<template>
  <div v-if="showForm">
    <v-toolbar
      elevation="0"     
    >
      <v-icon large style="margin-right:10px;">mdi-sun-thermometer</v-icon>
      <v-toolbar-title>
        {{ $t('meteo.title', locale) }}
      </v-toolbar-title>
    </v-toolbar>
    <v-progress-linear indeterminate v-if="loading" />
    <v-container v-else-if="editedItem">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Lugar"
            :label="$t('meteo.name', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.NombreMun"
            :label="$t('meteo.zone', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.URL"
            :label="$t('meteo.URL', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="12"
          md="12"
        >
          <v-checkbox
            v-model="editedItem.Turismo"
            :label="$t('meteo.turismo', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Lat"
            :label="$t('meteo.lat', locale)"
            hide-details
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
          md="6"
        >
          <v-text-field
            v-bind="{ 'outlined' : true}"
            v-model="editedItem.Lon"
            :label="$t('meteo.lon', locale)"
            hide-details
          />
        </v-col>
      </v-row>
    </v-container>
    <action-buttons
      v-if="editedItem"
      :id="editedItem.ID"
      :onDeleteItem="handleDelete"
      :onSaveItem="handleSave"
      :onClose="onClose"
      :locale="locale"
    />
  </div>
  <div v-else>
    <v-btn
      block
      color="primary"
      @click="handleForceMeteoUpdate"
    >
      force update
    </v-btn>
  </div>
</template>
<script>
  const defaultItem = {
    ID: null,
    Lugar: '',
    NombreMun: '',
    URL: "https://www.eltiempo.es/",
  }
  
  import { mapState } from 'vuex'
  import api from '@/services/api'
  import constants from '@/constants'
  import ActionButtons from '@/components/ActionButtons'
  export default{
    name: 'WorkspaceForm',
    components: {
      ActionButtons,
    },
    props: {
      editedID: {
        type: String,
        default: null
      },
      showForm: {
        type: Boolean,
        default: false
      },
      onDelete: {
        type: Function,
        required: true
      },
      onSave: {
        type: Function,
        required: true
      },
      onClose: {
        type: Function,
        required: true
      },
    },
    data () {
      return {
        loading: false,
        editedItem: null
      }
    },
    computed: {
      ...mapState('app',['locale']),
      timezones () {
        return constants.timezones
      }
    },
    watch: {
      showForm (v) {
        if (v) {
          if(this.editedID) this.handleGetData()
          else this.editedItem = JSON.parse(JSON.stringify(defaultItem))
        }
      },
      editedID (v) {
        if (this.showForm) {
          if (v) this.handleGetData()
          else this.editedItem = JSON.parse(JSON.stringify(defaultItem))
        }
      },
    },

    methods: {
      handleGetData () {
        this.loading = true
        api.getItem ('meteo', 'v1/system/meteos/zones/', this.editedID)
          .then(response => {
            response.ID = response.ID.toString()
            this.editedItem = response
            this.loading = false
          })
      },
      handleForceMeteoUpdate () {
        window.open(
          'https://meteo-api.dtouchwebapp.com/cron/update',
          '_blank' // <- This is what makes it open in a new window.
        )
      },
      handleSave () {
        this.onSave(this.editedItem)
      },
      handleDelete () {
        this.onDelete(this.editedItem.ID)
      }
    }
  }
</script>
